<template>
  <div class="index">
    <div class="newsEchartsBox">
      <div style="padding-top: 1vw">
        <!-- <p v-if="optionList.length === 0" style="margin: 30px 0 0 30px;font-weight: bold;">当前无任何权限 !</p> -->
        <div style="display: flex; justify-content: center">
          <el-input
            placeholder="请输入企业名称"
            v-model="searchValue"
            class="input-with-select"
            style="width: 50vw; margin-top: 10vh; margin-bottom: 5vh"
            @keyup.enter.native="searchCompanyList(searchValue)"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              type="primary"
              @click="searchCompanyList(searchValue)"
            ></el-button>
          </el-input>
        </div>
        <div
          class="industryType"
          v-for="o in optionList"
          :key="o.industry_type"
          style="margin-bottom: 25px"
        >
          <!-- <p
            style="
              margin: 10px 0 0 0;
              color: #337ab7;
              font-weight: bold;
              font-size: 18px;
            "
          >
            {{ o.industry_type }}
          </p> -->
          <div class="industryBox">
            <div
              class="industryBox-item"
              v-for="i in o.option_list"
              :key="i.image_url"
              @click="handleClickIndustry(i)"
            >
              <img
                :src="i.image_url"
                :class="i.is_online == 0 ? 'not_online' : ''"
              />
              <div class="industryBox-text">{{ i.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="请选择您想查看的公司" :visible.sync="companyListVisible">
      <el-radio-group
        v-model="radio"
        v-loading="companyLoading"
        @change="handleChangeRadio"
      >
        <el-radio
          v-for="i in companyList"
          :label="i.full_name"
          :key="i.id"
          style="margin-bottom: 10px"
          >{{ i.full_name }}</el-radio
        >
      </el-radio-group>
    </el-dialog>
    <el-dialog title="公司信息" :visible.sync="showInfo" width="90%">
      <div id="introduce" class="introduce">
        <!-- <img class="close" src="@/assets/img/close.png" @click="showIntroduce = false" /> -->
        <div style="display: flex">
          <div class="detail">
            <div class="detail-mask" v-if="false">
              <p>当前未收录该公司的详细信息</p>
            </div>
            <div class="detailTop">
              <!-- <img src="@/assets/img/companyLogo.png" alt /> -->
              <div class="compLogo">
                <p>{{ compNameForLogo }}</p>
              </div>
              <div class="des">
                <h1>
                  {{ companyInfo.entity_name }}
                  <!-- <span>股票：{{ companyInfo.stock_code || "暂无" }}</span> -->
                </h1>
                <p>
                  <span>电话：{{ companyInfo.telephone || "暂无" }}</span>
                  <span
                    style="cursor: pointer"
                    @click="goto(companyInfo.website)"
                    >官网： {{ companyInfo.website || "暂无" }}</span
                  >
                </p>
                <p>
                  <span>邮箱：{{ companyInfo.email || "暂无" }}</span>
                  <span>地址：{{ companyInfo.office_addr || "暂无" }}</span>
                </p>
              </div>
            </div>
            <table>
              <tbody>
                <tr>
                  <td>法定代表人</td>
                  <td>{{ companyInfo.legal_representative || "暂无" }}</td>
                  <td>公司类别</td>
                  <td>{{ companyInfo.company_type || "暂无" }}</td>
                  <td>成立日期</td>
                  <td>{{ companyInfo.founded_date || "暂无" }}</td>
                </tr>
                <tr>
                  <td>注册资本</td>
                  <td>{{ companyInfo.regis_capital || "暂无" }}</td>
                  <td>纳税人识别号</td>
                  <td>{{ companyInfo.regis_code || "暂无" }}</td>
                  <td>所属地区</td>
                  <td>{{ companyInfo.headquater || "暂无" }}</td>
                </tr>
                <tr>
                  <td>员工人数</td>
                  <td>{{ companyInfo.employees || "暂无" }}</td>
                  <td>公司英文</td>
                  <td>{{ companyInfo.name_en || "暂无" }}</td>
                  <td>是否上市</td>
                  <td>
                    {{ companyInfo.listed ? companyInfo.listed : "未知" }}
                  </td>
                </tr>
                <!-- <tr>
            <td>Moody’s</td>
            <td>{{data['commercial_info/Moody’s']||'暂无'}}</td>
            <td>income_structure</td>
            <td>{{data['commercial_info/income_structure']||'暂无'}}</td>
            <td>{{"S&P"}}</td>
            <td>{{data['commercial_info/S&P']||'暂无'}}</td>
            </tr>-->
                <tr>
                  <td>产业标签</td>
                  <td
                    colspan="5"
                    style="text-align: left; word-break: break-all"
                  >
                    {{ companyInfo.industry || "暂无" }}
                  </td>
                </tr>
                <tr>
                  <td>经营范围</td>
                  <td
                    colspan="5"
                    style="text-align: left; word-break: break-all"
                  >
                    {{ companyInfo.domain || "暂无" }}
                  </td>
                </tr>
                <tr>
                  <td>描述</td>
                  <td
                    colspan="5"
                    style="text-align: left; word-break: break-all"
                  >
                    {{ companyInfo.company_profile || "暂无" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- <div style="margin-top: 15px; margin-bottom: 15px; width: 35%">
            <p
              style="
                font-size: 16px;
                font-weight: bold;
                color: #333333;
                margin-left: 15px;
                margin-left: ;
              "
            >
              ▪ 评分明细
            </p>
            <div
              style="
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              "
            >
              <p
                style="
                  text-align: center;
                  font-weight: bold;
                  font-size: 16px;
                  color: #2c6c8f;
                "
              >
                综合评分：{{ radarTotal }}
              </p>
              <score-radar :apiData="radarData"></score-radar>
            </div>
          </div> -->
        </div>
        <!-- <div style="margin-top: 15px;margin-bottom: 15px;width: 100%;">
          <p style="font-size: 16px;font-weight: bold;color: #333333;margin-left: 15px;margin-left:">▪ 评分明细</p>
          <score-radar :apiData="radarData"></score-radar>
        </div> -->
        <company-detail
          :tagList="tagList"
          :productList="productList"
          :industryPaquList="industryPaquList"
          :chartDataLink="chartDataLink"
          :chartLinkShortName="chartLinkShortName"
          :onlySub="onlySub"
          :chartDataFinancial="chartDataFinancial"
          :chartDataProduct="chartDataProduct"
          :chartProductYearList="chartProductYearList"
          :detailTrTopVal="detailTrTopVal"
          :patentEchartsData="patentEchartsData"
          :wordCloudData2="wordCloudData2"
          :patentListData="patentListData"
          :collapseActiveNames="collapseActiveNames"
          @showMorePatent="showMorePatent()"
          @collapseActive="handleCollapseActive"
        ></company-detail>
      </div>
      <!-- <div slot="footer" class="dialog-footer">
      <el-button @click="outerVisible = false" type="primary">关 闭</el-button>
    </div> -->
    </el-dialog>
  </div>
</template>
<script>
import api from "@/api/api";
import cookie from "@/utils/common.js";
import { EventBus } from "../utils/eventBus.js";
import CompanyDetail from "../components/CompanyDetail.vue"

export default {
  components: {
    CompanyDetail
  },
  data() {
    return {
      optionList: [],
      searchValue: "",
      companyListVisible: false,
      companyList: [],
      companyLoading: false,
      showInfo: false,
      radio: "",
      compNameForLogo: "",
      companyInfo: {
        entity_name: "",
        telephone: "",
        website: "",
        email: "",
        office_addr: "",
        description: "",
        label: "",
        legal_representative: "",
        company_type: "",
        founded_date: "",
        regis_capital: "",
        regis_code: "",
        headquater: "",
        employees: "",
        name_en: "",
        listed: "",
        domain: "",
        stock_code: "",
        industry: "",
        company_profile: ""
      },
      tagList: [],
      productList: [],
      industryPaquList: [],
      chartDataLink: {},
      chartLinkShortName: "",
      onlySub: false,
      chartDataFinancial: [],
      chartDataProduct: {},
      chartProductYearList: [],
      detailTrTopVal: [],
      patentEchartsData: [],
      wordCloudData2: {},
      patentListData: [],
      radarData: [],
      collapseActiveNames: ["1", "2", "3", "4", "5", "6"],
      patentParams: {
        industry: "",
        domain_id: "",
        company_guogao_id: "",
        page: 1,
        page_size: 5,
        filter_type: "相关公司专利",
      },
    };
  },
  methods: {
    // 获取公司详情信息
    async getCompanyInfo(row) {
      console.log("val", row);
      let params = {
        full_name: row.full_name,
      };
      this.radarData = [
        row.scale,
        row.technology,
        row.customer,
        row.brand,
        // row.risk + 100
        row.vitality
      ]
      this.companyInfo = {}
      this.radarTotal = row.comprehensive
      console.log("radarData", this.radarData)
      api.getCompanyInfo(params).then((res) => {
        console.log("公司信息", res);
        let info = res.data[0];
        this.companyInfo.entity_name = info.full_name;
        this.companyInfo.telephone = info.telephone;
        this.companyInfo.website = info.website;
        this.companyInfo.email = info.email;
        this.companyInfo.office_addr = info.address;
        this.companyInfo.description = "";
        this.companyInfo.label = "";
        this.companyInfo.legal_representative = info.representative;
        this.companyInfo.company_type = info.enterprises_type;
        this.companyInfo.founded_date = info.establishment;
        this.companyInfo.regis_capital = info.registered_capital;
        this.companyInfo.regis_code = info.taxpayer_num;
        this.companyInfo.headquater = info.region;
        this.companyInfo.employees = info.insured_num;
        this.companyInfo.name_en = info.english_name;
        this.companyInfo.listed = info.is_on_market;
        this.companyInfo.domain = info.business_scope;
        this.companyInfo.industry = info.industry
        this.companyInfo.company_profile = info.company_profile
        this.compNameForLogo = info.short_name.substring(0, 4);
        this.chartLinkShortName = info.short_name
        this.showInfo = true;
      })
      let company_id = await api.getCompanyId(params).then(res => {
        console.log("获取公司company_id", res.data)
        return res.data
      }).catch(err => {
        console.error("获取公司信息", err)
        this.tagList= []
        this.productList= []
        this.industryPaquList= []
        this.chartDataLink= {}
        this.chartLinkShortName= ""
        this.onlySub= false
        this.chartDataFinancial= []
        this.chartDataProduct= {}
        this.chartProductYearList= []
        this.detailTrTopVal= []
        this.patentEchartsData= []
        this.wordCloudData2= {}
        this.patentListData= []
        return 0
      })
      this.patentListData = []
      if (company_id == 0) { return}
      this.patentParams.company_id = company_id
      this.getProductTag(company_id)
      this.getLink(company_id)
      this.getFinancialAndProductInfo(company_id)
      this.getHolder(company_id)
      this.getPatentCountAndKeyword(company_id)
      this.getPatentList()
      console.log("chartDataProduct", this.chartDataProduct)
      console.log("chartDataFinancial", this.chartDataFinancial)
    },
    // 获取专利列表
    getPatentList() {
      // let params = {
      //   company_id: company_id,
      //   page: 1,
      //   page_size: 10,
      // };
      // this.patentLoading = true;
      // this.patentParams.company_id = company_id
      api.getPatentList(this.patentParams).then((res) => {
        console.log("-------------专利列表", res);
        // this.patentListData = res.data;
        this.patentListTotal = res.total;
        res.data.forEach(i => {
          this.patentListData.push(i)
        });
        // this.patentLoading = false;
      });
    },
    // 获取专利统计和关键词云
    getPatentCountAndKeyword(company_id) {
      let params = {
        company_id: company_id,
      };
      // this.patentLoading = true
      api.getCompanyBaseInfo(params).then((res) => {
        console.log("专利", res);
        this.patentEchartsData = res.data[0].year_count_list;
        this.wordCloudData2 = res.data[0].patent_keywords;
        // this.patentLoading = false
      }).catch(err => {
        // this.patentLoading = false
      })
    },
    // 获取股东信息
    getHolder(company_id) {
      let params = {
        company_id: company_id,
        page: 1,
        page_size: 5,
      };
      // this.pdfLoading = true;
      api
        .getPdfAnnual(params)
        .then((res) => {
          console.log("年度报告", res);
          this.newsList = res.data;
          console.log("最新的newsList", this.newsList[0]);
          this.detailTrTopVal = [];
          // 载入最新股东信息
          this.detailTrTopVal[0] = [
            "股东名称",
            "股东性质",
            "持股比例",
            "持股数量",
            "持有有限条件的股份数量",
          ];
          if (this.newsList.length != 0 && this.newsList[0].shareholder) {
            this.newsList[0].shareholder.forEach((i) => {
              this.detailTrTopVal.push([
                i.name,
                i.attribute,
                i.share_ratio,
                i.share_num,
                i.share_condition_num,
              ]);
            });
          }
          // this.pageTotal = this.newsList.length;
          // this.pageTotal = res.total;
          // this.pdfLoading = false;
        })
        .catch((err) => {
          this.pdfLoading = false;
          // alert(err);
          // this.$message({
          //   message: err,
          //   type: "error",
          // });
        });
    },
    // 获取财务信息和产品信息
    async getFinancialAndProductInfo(company_id) {
      // this.companyInfoVisible = true;
      let params = {
        company_id: company_id,
      };
      // this.loading = true;
      await api
        .getFinancialInfo(params)
        .then((res) => {
          console.log("financial", res);
          this.chartDataFinancial = res.data;
          if (res.data.length != 0) {
            this.lineChartFinancialVisible = true;
          } else {
            this.lineChartFinancialVisible = false;
          }
        })
        .catch((err) => {
          // this.loading = false;
          // console.log(err);
        });
      await api.getProductInfo(params).then((res) => {
        console.log("产品信息", res);
        this.chartDataProduct = res.data;
        this.chartProductYearList = res.year_list;
        if (res.year_list.length != 0) {
          this.lineChartProductVisible = true;
        } else {
          this.lineChartProductVisible = false;
        }
      });
      this.$nextTick(() => {
        // this.loading = false;
      });
    },
    // 获取产业链上下游
    async getLink(company_id) {
      let params = {
        company_id: company_id,
      };
      // this.linkLoading = true;
      await api
        .getLink(params)
        .then((res) => {
          console.log("LINK", res);
          this.chartDataLink = res.data;
          if (
            res.data.chain_company_link.length == 0 &&
            res.data.chain_product_link.length == 0
          ) {
            this.onlySub = true;
          } else {
            this.onlySub = false;
          }
          // this.chartLinkShortName = this.short_name;
          // this.linkLoading = false;
        })
        .catch((err) => {
          // this.linkLoading = false;
          // this.$message.error("服务器出错，请重试!")
        });
      // this.setGraphChart();
    },
    // 获取公司产业标签
    getProductTag(company_id) {
      this.tagList = [];
      this.productList = [];
      this.industryPaquList = [];
      let params = {
        company_id: company_id,
      };
      // this.tagLoading = true;
      api
        .getProductTag(params)
        .then((res) => {
          console.log("产业标签", res);
          // this.productList = res.data.product;
          // 去除为空的标签
          res.data.product.forEach((i) => {
            if (i != "") {
              this.productList.push(i);
            }
          });

          // 正式环境
          // res.data.product_list.forEach(i => {
          //   this.industryPaquList.push(i)
          // })
          // console.log("indexof");
          // this.industryPaquList = [...new Set(this.industryPaquList)];
         // 正式环境

          if (res.data.product_detail) {
            this.tagList = res.data.product_detail.split("；");
            this.tagList.pop();
            this.tagList = [...new Set(this.tagList)];
          }
          if (res.data.industry_paqu) {
            this.industryPaquList = res.data.industry_paqu.split("；");
            this.industryPaquList.pop();
          }

          // this.tagLoading = false;
        })
        .catch((err) => {
          // this.tagLoading = false;
          // this.$message.error("服务器出错，请重试!")
        });
    },
    showMorePatent() {
      this.patentParams.page += 1
      console.log("page", this.patentParams.page)
      if (this.patentParams.page <= 100) {
        this.getPatentList();
      } else {
        // this.showMoreText = "已无更多"
      }
    },
    // 获取专利列表
    getPatentList() {
      // let params = {
      //   company_id: company_id,
      //   page: 1,
      //   page_size: 10,
      // };
      // this.patentLoading = true;
      // this.patentParams.company_id = company_id
      api.getPatentList(this.patentParams).then((res) => {
        console.log("-------------专利列表", res);
        // this.patentListData = res.data;
        this.patentListTotal = res.total;
        res.data.forEach(i => {
          this.patentListData.push(i)
        });
        // this.patentLoading = false;
      });
    },
    // 搜索框搜索公司
    searchCompanyList(val) {
      this.radio = ""
      let params = {
        keyword: val,
      };
      this.companyListVisible = true;
      this.companyLoading = true;
      api.getCompanyList(params).then((res) => {
        console.log("搜索框搜索公司", res);
        this.companyList = res.data;
        this.companyLoading = false;
      });
    },
    // 单选框选择
    handleChangeRadio(val) {
      console.log(val);
      let params = {
        full_name: val,
        // scale: 80,
        // technology: 80,
        // customer: 80,
        // brand: 80,
        // vitality: 80
      }
      this.companyListVisible = false
      this.getCompanyInfo(params)
    },
    // 点击产业选项
    handleClickIndustry(i) {
      sessionStorage.setItem("index_industry", JSON.stringify(i));
      this.$store.commit("changeIndexIndustry", i.name);
      EventBus.$emit("index_industry", i);
      console.log("emit [index_industry]", i);
      this.$router.push("/industry_structure");
    },
    // 获取产业选项
    getIndustryGraphOptionRadar() {
      this.optionList = [];
      api
        .getIndustryGraphOptionRadar()
        .then((res) => {
          console.log("option", res);
          // this.optionList = res.data
          this.optionList.push(res.data[0]);
        })
        .catch((err) => {
          // console.log(err);
        });
    },
  },
  mounted() {
    this.getIndustryGraphOptionRadar();
  },
  beforeDestroy() {},
};
</script>

<style lang="scss">
.index {
  // margin-top: 52px;
  width: 100vw;
  margin-top: -6vh;
  // padding-bottom: 10px;
  min-height: 90vh;
  background-color: white;
  display: flex;
  justify-content: center;
  // background-color: gray;
  // margin-left: 0.5%;
  // min-height: 80vh;
  // padding-bottom: 30px;
  // max-height: 90vh;
  // display: flex;
  // justify-content: space-between;
  // background-color: white;
  .industryBox {
    width: 60vw;
    // height: 30vw;
    // margin-left: 9.5vw;
    margin-top: 1vw;
    // border: 1px solid black;
    flex-wrap: wrap;
    // justify-content: space-between;
    display: flex;
    &-item {
      width: 10.5vw;
      height: 10.5vw;
      position: relative;
      margin-left: 1vw;
      cursor: pointer;
      margin-bottom: 1vw;
      // background-color: #333333;
      img,
      .not_online {
        width: 100%;
        height: 100%;
        filter: brightness(50%);
      }
      .not_online {
        filter: contrast(5%);
      }
    }
    &-text {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      top: 0;
      justify-content: center;
      text-align: center;
      align-items: center;
      color: white;
      font-size: 20px;
      // word-wrap: break-word;
    }
  }
  .introduce {
    // position: absolute;
    // bottom: 9vh;
    width: 100%;
    // min-height: 0vh;
    border: 1px solid #e4eef6;
    // z-index: 1000;
    // transition-duration: 1s;
    // background-color: rgb(0, 0, 51);
    // box-shadow: inset 0px 0px 5px 3px rgb(5, 5, 129);
    .close {
      cursor: pointer;
      width: 23px;
      height: 23px;
      position: absolute;
      right: 10px;
      top: 10px;
    }
    table {
      width: 100%;
      color: #333333;
      margin-left: 0;
      // background-color: rgba($color: #000000, $alpha: 0.4);
      td {
        // border-bottom: solid rgba($color: white, $alpha: 0.3) 1px;
        padding: 10px;
        text-align: center;
      }
      td:nth-child(odd) {
        width: 13%;
        background-color: #f2f9fc;
      }
      td:nth-child(even) {
        width: 20%;
      }
    }
    .description {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    .des {
      overflow: hidden;
      flex: 1;
      padding: 10px;
      color: black;
      p {
        font-size: 14px;
        margin: 5px 0;
        span {
          display: inline-block;
          width: 48%;
          margin: 0 5px;
        }
      }
      h1 {
        margin: 5px 0;
        font-size: 18px;
        span {
          font-size: 14px;
          margin: 0 5px;
          //   background: #E7F4FF;
          color: cornflowerblue;
        }
      }
    }
    .detail {
      width: 100%;
      // max-width: 1200px;
      &-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        // background-color: rgba(91, 91, 94, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          color: #d3feff;
          font-size: 30px;
          font-weight: bold;
        }
      }
      .detailTop {
        display: flex;
        // background-color: rgba($color: #000000, $alpha: 0.4);
        border-bottom: solid silver 1px;
        // img {
        //   margin: 20px 10px 20px 25px;
        //   object-fit: contain;
        //   height: 80px;
        //   width: 80px;
        //   background: white;
        // }
      }
      .compLogo {
        margin: 20px 10px 20px 25px;
        height: 80px;
        width: 80px;
        // background-color: white;
        // border: 1px solid gray;
        background-color: #01a4d9;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          width: 80px;
          overflow: hidden;
          word-break: break-all;
          word-wrap: wrap;
          margin-left: 7px;
          text-align: center;
          color: white;
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 8px;
        }
      }
    }
  }
}
</style>
