<template>
  <div>
    <el-collapse
      class="collapseBox"
      v-model="activeName"
    >
      <el-collapse-item title="▪ 产业标签" name="1" v-if="tagList.length!=0 || productList.length!=0 || industryPaquList.length!=0">
        <div class="tagBox" v-if="tagList">
          <!-- <div class="tagBlue" v-for="i in tagList" :key="i">{{ i }}</div> -->
          <el-tag
            class="tagBox-tag"
            size="small"
            v-for="i in tagList"
            :key="i"
            >{{ i }}</el-tag
          >
        </div>
        <div class="tagBox" v-if="productList">
          <!-- <div class="sourceItem-tag" v-for="i in productList" :key="i">{{ i }}</div> -->
          <el-tag
            class="tagBox-tag"
            size="small"
            v-for="i in productList"
            :key="i"
            type="success"
            >{{ i }}</el-tag
          >
        </div>
        <div class="tagBox" v-if="industryPaquList">
          <!-- <div class="sourceItem-tag2" v-for="i in industryPaquList" :key="i">{{ i }}</div> -->
          <el-tag
            class="tagBox-tag"
            size="small"
            v-for="i in industryPaquList"
            :key="i"
            type="warning"
            >{{ i }}</el-tag
          >
        </div>
      </el-collapse-item>
      <el-collapse-item title="▪ 产业链信息" name="2">
        <link-echarts
          :apiDataGraphChart="chartDataLink"
          :shortName="chartLinkShortName"
          :onlySub="onlySub"
        ></link-echarts>
      </el-collapse-item>
      <el-collapse-item title="▪ 财务信息" name="3" v-if="chartDataFinancial.length != 0">
        <div class="bombBox-content_v2">
          <financial-echarts
            ref="financialEcharts"
            :apiDataLineChart="chartDataFinancialReal"
          ></financial-echarts>
        </div>
      </el-collapse-item>
      <el-collapse-item title="▪ 营收构成" name="4" v-if="chartDataFinancial.length != 0">
        <div class="bombBox-content_v2">
          <product-echarts
            ref="productEcharts"
            :apiDataLineChart="chartDataProduct"
            :apiYearList="chartProductYearList"
          ></product-echarts>
        </div>
      </el-collapse-item>
      <el-collapse-item title="▪ 股东信息" name="5" v-if="detailTrTopVal.length != 1">
        <div class="bombBox-content_v2" style="margin-bottom: 10px">
          <table
            class="holderTable"
            border="1"
            cellpadding="0"
            cellspacing="0"
            v-if="detailTrTopVal.length != 1"
          >
            <tbody>
              <tr v-for="t in detailTrTopVal" :key="t[0]">
                <td v-for="(d, index) in t" :key="d + index.toString()">
                  {{ d }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-collapse-item>
      <el-collapse-item title="▪ 专利信息" name="6" v-if="patentEchartsData">
        <div
          class="bombBox-content_v2 flexBox"
          style="padding-bottom: 20px"
        >
          <patent-echarts
            :apiDataLineChart="patentEchartsData"
          ></patent-echarts>
          <keyword-cloud :apiDataLineChart="wordCloudData2"></keyword-cloud>
        </div>
        <patent-list :apiDataLineChart="patentListData"></patent-list>
        <div class="showMore" @click="showMorePatent">
          <p>{{ showMoreText }}</p>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import api from "@/api/api";
import cookie from "@/utils/common.js";
import { EventBus } from "../utils/eventBus.js";
import LinkEcharts from "@/components/LinkEcharts.vue"
import FinancialEcharts from "@/components/FinancialEcharts.vue"
import ProductEcharts from "@/components/ProductEcharts.vue"
import PatentEcharts from "@/components/PatentEcharts.vue"
import KeywordCloud from "@/components/KeywordCloud.vue"
import PatentList from "@/components/PatentList.vue"

export default {
  components: {
    LinkEcharts,
    FinancialEcharts,
    ProductEcharts,
    PatentEcharts,
    KeywordCloud,
    PatentList
  },
  props: {
    tagList: Array,
    productList: Array,
    industryPaquList: Array,
    chartDataLink: Object,
    chartLinkShortName: String,
    onlySub: Boolean,
    chartDataFinancial: Array,
    chartDataProduct:  Object,
    chartProductYearList: Array,
    detailTrTopVal: Array,
    patentEchartsData: Array,
    wordCloudData2: Object,
    patentListData: Array,
    collapseActiveNames: Array
  },
  watch: {
    collapseActiveNames: {
      handler(newValue, oldValue) {
        this.activeName = newValue
      }
    },
    activeName: {
      handler(newValue, oldValue) {
        this.$emit("collapseActive", newValue)
      }
    },
    chartDataFinancial: {
      handler(newValue, oldValue) {
        console.log("newVal-chartDataFinancial", newValue)
        this.chartDataFinancialReal = []
        this.$nextTick(() => {
          this.chartDataFinancialReal = newValue
        })
        
      }
    }
  },
  data() {
    return {
      showMoreText: "加载更多",
      activeName: ["1", "2", "3", "4", "5", "6"],
      chartDataFinancialReal: []
    };
  },
  methods: {
    // 加载更多专利
    showMorePatent() {
      this.$emit("showMorePatent")
    },
    // 获取股东信息
    getHolder(company_id) {
      let params = {
        company_id: company_id,
        page: 1,
        page_size: 5,
      };
      // this.pdfLoading = true;
      api
        .getPdfAnnual(params)
        .then((res) => {
          console.log("年度报告", res);
          this.newsList = res.data;
          console.log("最新的newsList", this.newsList[0]);
          this.detailTrTopVal = [];
          // 载入最新股东信息
          this.detailTrTopVal[0] = [
            "股东名称",
            "股东性质",
            "持股比例",
            "持股数量",
            "持有有限条件的股份数量",
          ];
          if (this.newsList.length != 0 && this.newsList[0].shareholder) {
            this.newsList[0].shareholder.forEach((i) => {
              this.detailTrTopVal.push([
                i.name,
                i.attribute,
                i.share_ratio,
                i.share_num,
                i.share_condition_num,
              ]);
            });
          }
          // this.pageTotal = this.newsList.length;
          // this.pageTotal = res.total;
          // this.pdfLoading = false;
        })
        .catch((err) => {
          this.pdfLoading = false;
          // alert(err);
          this.$message({
            message: err,
            type: "error",
          });
        });
    },
    // 获取财务信息和产品信息
    async getFinancialAndProductInfo(company_id) {
      // this.companyInfoVisible = true;
      let params = {
        company_id: company_id,
      };
      // this.loading = true;
      await api
        .getFinancialInfo(params)
        .then((res) => {
          console.log("financial", res);
          this.chartDataFinancial = res.data;
          if (res.data.length != 0) {
            this.lineChartFinancialVisible = true;
          } else {
            this.lineChartFinancialVisible = false;
          }
        })
        .catch((err) => {
          // this.loading = false;
          console.log(err);
        });
      await api.getProductInfo(params).then((res) => {
        console.log("产品信息", res);
        this.chartDataProduct = res.data;
        this.chartProductYearList = res.year_list;
        if (res.year_list.length != 0) {
          this.lineChartProductVisible = true;
        } else {
          this.lineChartProductVisible = false;
        }
      });
      this.$nextTick(() => {
        // this.loading = false;
      });
    },
    // 获取产业链上下游
    async getLink(company_id) {
      let params = {
        company_id: company_id,
      };
      // this.linkLoading = true;
      await api
        .getLink(params)
        .then((res) => {
          console.log("LINK", res);
          this.chartDataLink = res.data;
          if (
            res.data.chain_company_link.length == 0 &&
            res.data.chain_product_link.length == 0
          ) {
            this.onlySub = true;
          } else {
            this.onlySub = false;
          }
          // this.chartLinkShortName = this.short_name;
          // this.linkLoading = false;
        })
        .catch((err) => {
          // this.linkLoading = false;
          // this.$message.error("服务器出错，请重试!")
        });
      // this.setGraphChart();
    },
    // 获取公司产业标签
    getProductTag(company_id) {
      this.tagList = [];
      this.productList = [];
      this.industryPaquList = [];
      let params = {
        company_id: company_id,
      };
      // this.tagLoading = true;
      api
        .getProductTag(params)
        .then((res) => {
          console.log("产业标签", res);
          // this.productList = res.data.product;
          // 去除为空的标签
          res.data.product.forEach((i) => {
            if (i != "") {
              this.productList.push(i);
            }
          });

          // 正式环境
          // res.data.product_list.forEach(i => {
          //   this.industryPaquList.push(i)
          // })
          // console.log("indexof");
          // this.industryPaquList = [...new Set(this.industryPaquList)];
          // 正式环境

          if (res.data.product_detail) {
            this.tagList = res.data.product_detail.split("；");
            this.tagList.pop();
            this.tagList = [...new Set(this.tagList)];
          }
          if (res.data.industry_paqu) {
            this.industryPaquList = res.data.industry_paqu.split("；");
            this.industryPaquList.pop();
          }

          // this.tagLoading = false;
        })
        .catch((err) => {
          // this.tagLoading = false;
          // this.$message.error("服务器出错，请重试!")
        });
    },
    handleCollapse(val) {
      console.log("collapse", val);
      if (val.indexOf("3") != -1) {
        EventBus.$emit("financial_rerender", 1);
      }
      if (val.indexOf("4") != -1) {
        EventBus.$emit("product_rerender", 1);
      }
    },
    // 获取公司company_id
    getCompanyId(full_name) {
      let params = {
        full_name: full_name,
      };
      api.getCompanyId(params).then((res) => {
        console.log("获取公司company_id", res.data);
        return res.data;
      });
    },
    // 监听招聘岗位排行点击
    listenCompanyPostRank() {
      EventBus.$on("company_post_rank", (data) => {
        this.post_params.post_classification = data;
        this.post_params.page = 1;
        this.post_params.company_id = "";
        this.getPostInfo();
      });
    },
    // 获取招聘岗位统计
    getPostCount(node) {
      this.postCount.legend = [];
      this.postCount.data = [];
      this.postCount.name = {};
      let params = {
        industry: this.patentParams.industry,
        industry_node: node,
      };
      this.postCountLoading = true;
      api
        .getPostCount(params)
        .then((res) => {
          console.log("岗位统计", res);
          res.data.forEach((i) => {
            let legend_item =
              i.name.length > 4 ? i.name.substring(0, 4) + "..." : i.name;
            this.postCount.legend.push(legend_item);
            this.postCount.name[legend_item] = i.post_all;
            this.postCount.data.push({
              value: i.count,
            });
          });
          this.postCountLoading = false;
        })
        .catch((err) => {
          this.postCountLoading = false;
        });
    },
    // 获取招聘散点分布
    getPostScatter(node) {
      let params = {
        industry: this.patentParams.industry,
        industry_node: node,
      };
      this.talentData = [];
      api.getPostScatter(params).then((res) => {
        // console.log("招聘散点", res)
        this.talentData = res.data;
      });
    },
    // 获取招聘月度排行
    getPostMonthRank(node) {
      let params = {
        industry: this.patentParams.industry,
        industry_node: node,
      };
      api.getPostMonthRank(params).then((res) => {
        // console.log("月度排行", res)
        this.talentEchartsData = res.data;
      });
    },
    // 点击招聘信息
    showPostDetail(val) {
      // console.log(val)
      this.getPostDetail(val.id);
      this.postInfoDetail = {};
      this.postInfoDetail.tags = [];
      this.postInfoDetail.keyword_tags = [];
      this.showPost = true;
    },
    // 获取招聘信息详情
    getPostDetail(id) {
      let params = {
        id: id,
      };
      api.getPostDetail(params).then((res) => {
        // console.log(res)
        this.postInfoDetail = res.data;
        this.postInfoDetail.tags = res.data.other_label.split("|");
        this.postInfoDetail.keyword_tags = res.data.key_word.split("|");
      });
    },
    // 获取公司招聘排行
    getPostRank(node) {
      let params = {
        industry: this.patentParams.industry,
        industry_node: node,
      };
      this.postCompanyLoading = true;
      this.postCompanyCount.legend = [];
      this.postCompanyCount.data = [];
      this.postCompanyCount.name = {};
      api
        .getPostRank(params)
        .then((res) => {
          // console.log("招聘排行", res)
          res.data.forEach((i) => {
            let legend_item =
              i.short_name.length > 4
                ? i.short_name.substring(0, 4) + "..."
                : i.short_name;
            this.postCompanyCount.legend.push(legend_item);
            this.postCompanyCount.name[legend_item] = i.full_name;
            // this.postCompanyCount.data.push(i.num);
            this.postCompanyCount.data.push({
              value: i.count,
              company_id: i.company_id,
            });
          });
          this.postCompanyLoading = false;
        })
        .catch((err) => {
          this.postCompanyLoading = false;
        });
      // console.log("postCompanyCount", this.postCompanyCount)
    },
    // 分页器变化
    handlePostPageChange(val) {
      // console.log("分页", val)
      this.post_params.page = val;
      this.getPostInfo();
    },
    // 获取公司招聘信息
    getPostInfo() {
      // let params = {
      //   company_id: "",
      //   page: 1,
      //   page_size: 10
      // }
      this.postLoading = true;
      api
        .getPostInfo(this.post_params)
        .then((res) => {
          // console.log(res.data)
          this.post_list = res.data;
          this.post_total = res.count;
          this.postLoading = false;
        })
        .catch((err) => {
          this.postLoading = false;
        });
    },
    // 过滤类型切换
    handleTypeChange(val) {
      // console.log(val);
      if (this.patentParams.domain_id) {
        this.patent_list = [];
        this.getIndustryPatent();
      }
    },
    // 获取产业生态位
    getIndustryPosition() {
      let params = {
        industry:
          this.patentParams.industry != "air_conditioning"
            ? this.patentParams.industry
            : "ele_car",
      };
      this.positionLoading = true;
      api.getIndustryPosition(params).then((res) => {
        // console.log("生态位", res);
        this.links_position = {
          upUpStream: [],
          upStream: [],
          mediumStream: [],
          downStream: [],
        };
        res.data.forEach((i) => {
          if (i.chain == 0) {
            if (!i.father_id) {
              this.links_position.upUpStream.push({
                name: i.name,
                id: i.id,
                children: [],
              });
            }
          }
          if (i.chain == 1) {
            if (!i.father_id) {
              this.links_position.upStream.push({
                name: i.name,
                id: i.id,
                children: [],
              });
            }
          }
          if (i.chain == 2) {
            if (!i.father_id) {
              this.links_position.mediumStream.push({
                name: i.name,
                id: i.id,
                children: [],
              });
            }
          }
          if (i.chain == 3) {
            if (!i.father_id) {
              this.links_position.downStream.push({
                name: i.name,
                id: i.id,
                children: [],
              });
            }
          }
        });
        res.data.forEach((i) => {
          if (i.chain == 0 && i.father_id) {
            this.links_position.upUpStream.forEach((j) => {
              if (j.id == i.father_id) {
                j.children.push({
                  name: i.name,
                });
              }
            });
          }
          if (i.chain == 1 && i.father_id) {
            this.links_position.upStream.forEach((j) => {
              if (j.id == i.father_id) {
                j.children.push({
                  name: i.name,
                });
              }
            });
          }
          if (i.chain == 2 && i.father_id) {
            this.links_position.mediumStream.forEach((j) => {
              if (j.id == i.father_id) {
                j.children.push({
                  name: i.name,
                });
              }
            });
          }
          if (i.chain == 3 && i.father_id) {
            this.links_position.downStream.forEach((j) => {
              if (j.id == i.father_id) {
                j.children.push({
                  name: i.name,
                });
              }
            });
          }
        });
        console.log("数据格式化", this.links_position);
        this.positionLoading = false;
      });
    },
    // 点击查看评分项明细
    async handleCheckDetail() {
      this.showScoreDetail = await true;
      // setTimeout(() => {
      //   document.getElementsByClassName(
      //     "el-table__body-wrapper is-scrolling-middle"
      //   )[0].scrollLeft = 0;
      // }, 500)
    },
    // 获取公司评分列表
    getCompanyScore() {
      let params = {
        node_id: this.domain_id,
      };
      this.downstreamLoading = true;
      let latentCompAreaList = [];
      let globalCompAreaList = [];
      this.downstreamCompanyList = [];
      this.downstreamCompanyListChina = [];
      this.downstreamCompanyListGlobal = [];
      api.getCompanyScore(params).then((res) => {
        console.log("公司评分列表", res);
        res.data.forEach((i) => {
          if (i.short_name) {
            i.name = i.short_name;
          } else {
            i.name = i.full_name;
          }
          // this.downstreamCompanyList.push(i);
          if (i.country == "中国") {
            this.downstreamCompanyListChina.push(i);
          } else {
            this.downstreamCompanyListGlobal.push(i);
          }
        });
        if (this.mapTabMarket == 1) {
          this.downstreamCompanyList = this.downstreamCompanyListChina;
        } else {
          this.downstreamCompanyList = this.downstreamCompanyListGlobal;
        }
        let arrProvince = new Set();
        let arrCountry = new Set();
        res.data.forEach((i) => {
          if (i.loc_province && i.country == "中国") {
            arrProvince.add(i.loc_province.replace("省", "").replace("市", ""));
          }
          if (i.country && i.country != "中国") {
            arrCountry.add(i.country);
          }
        });
        arrProvince.forEach((i) => {
          latentCompAreaList.push({
            loc_province: i,
            value: 0,
          });
        });
        arrCountry.forEach((i) => {
          globalCompAreaList.push({
            name: i,
            value: 0,
          });
        });
        res.data.forEach((item) => {
          for (let i = 0; i < latentCompAreaList.length; i++) {
            if (
              latentCompAreaList[i].loc_province &&
              latentCompAreaList[i].loc_province ==
                item.loc_province.replace("省", "").replace("市", "")
            ) {
              latentCompAreaList[i].value += 1;
            }
          }
          for (let i = 0; i < globalCompAreaList.length; i++) {
            if (globalCompAreaList[i].name == item.country) {
              globalCompAreaList[i].value += 1;
            }
          }
        });
      });
      this.mapLatentMarket = latentCompAreaList;
      this.worldListMarket = globalCompAreaList;
      this.downstreamLoading = false;
    },
    // 获取产业对应公司的专利排行
    getCompanyPatentCount() {
      let params = {
        industry: this.patentParams.industry,
        domain_id: this.patentParams.domain_id,
      };
      this.companyPatentCount.legend = [];
      this.companyPatentCount.data = [];
      this.companyPatentCount.name = {};
      this.barLoading = true;
      let startTime = new Date().valueOf();
      api
        .getCompanyPatentCount(params)
        .then((res) => {
          console.log("公司专利排行", res);
          res.data.forEach((i) => {
            let legend_item =
              i.name.length > 4 ? i.name.substring(0, 4) + "..." : i.name;
            this.companyPatentCount.legend.push(legend_item);
            this.companyPatentCount.name[legend_item] = i.full_name;
            // this.companyPatentCount.data.push(i.num);
            this.companyPatentCount.data.push({
              value: i.num,
              company_guogao_id: i.company_guogao_id,
            });
          });
          this.barLoading = false;
          console.log("companyPatentCount", this.companyPatentCount);
          let endTime = new Date().valueOf();
          console.log(
            `%c专利排行：%c${(endTime - startTime) / 1000}`,
            "color: blue;font-weight:bold",
            "color: orange;font-weight:bold"
          );
        })
        .catch((err) => {
          this.barLoading = false;
        });
    },
    // 公司列表查看明细
    checkDetail(row) {
      console.log("明细", row);
      this.showScoreDetail = true;
    },
    // 获取产业专利关键词
    getIndustryPatentKeyword() {
      let params = {
        industry: this.patentParams.industry,
        domain_id: this.patentParams.domain_id,
        company_guogao_id: this.patentParams.company_guogao_id,
      };
      this.keywordLoading = true;
      api
        .getIndustryPatentKeyword(params)
        .then((res) => {
          console.log("产业专利关键词", res);
          this.wordCloudData = res.data;
          this.keywordLoading = false;
        })
        .catch((err) => {
          this.keywordLoading = false;
        });
    },
    // 获取产业专利年度统计
    getIndustryPatentCount() {
      let params = {
        industry: this.patentParams.industry,
        domain_id: this.patentParams.domain_id,
      };
      this.patentCountLoading = true;
      let startTime = new Date().valueOf();
      api
        .getIndustryPatentCount(params)
        .then((res) => {
          console.log("年度统计", res);
          this.patentEchartsData = res.data;
          this.patentCountLoading = false;
          let endTime = new Date().valueOf();
          console.log(
            `%c产业专利年度统计：%c${(endTime - startTime) / 1000}`,
            "color: blue;font-weight:bold",
            "color: orange;font-weight:bold"
          );
        })
        .catch((err) => {
          this.patentCountLoading = false;
        });
    },
    // 获取产业市场简介
    getIndustryIntroduction() {
      let params = {
        industry_id: this.domain_id,
      };
      api.getIndustryIntroduction(params).then((res) => {
        console.log("产业简介", res);
        this.industryDesc = res.data;
      });
    },
    // 加载更多
    showMore() {
      this.patentParams.page += 1;
      this.getIndustryPatent();
    },
    // 获取产业专利
    getIndustryPatent() {
      // this.patent_list = []
      this.patentLoading = true;
      let startTime = new Date().valueOf();
      api
        .getIndustryPatent(this.patentParams)
        .then((res) => {
          console.log("产业专利", res);
          res.data.forEach((r) => {
            this.patent_list.push(r);
          });
          this.patentLoading = false;
          let endTime = new Date().valueOf();
          console.log(
            `%c获取产业专利：%c${(endTime - startTime) / 1000}`,
            "color: blue;font-weight:bold",
            "color: orange;font-weight:bold"
          );
          // this.patent_list = res.data
        })
        .catch((err) => {
          this.patentLoading = false;
        });
    },
    // 选择tab
    handleRadioChange(val) {
      console.log(val);
      if (val == "产业链条") {
        this.getIndustryTree(this.industrySelected);
        this.downstreamCompanyList = this.downstreamCompanyListChina;
        // this.getIndustryCompany(this.domain_id);
      } else if (val == "产业市场") {
        this.getIndustryTree(this.industrySelected);
        this.getIndustryCompany(this.industrySelected);
      } else if (val == "专利技术") {
        this.patentParams.page = 1;
        this.patentParams.company_guogao_id = "";
        // this.patentParams.industry = ""
        this.patentParams.domain_id = "";
        this.patent_list = [];
        console.log("点击专利技术this.patent_list", this.patent_list);
        console.log(
          "点击专利技术this.patentParams.page",
          this.patentParams.page
        );
        console.log(
          "点击专利技术this.patentParams.company_guogao_id",
          this.patentParams.company_guogao_id
        );
        this.getIndustryTree(this.industrySelected);
        this.getIndustryPatent();
        this.getIndustryPatentScatter();
        this.getIndustryPatentCount();
        this.getIndustryPatentKeyword();
        this.getCompanyPatentCount();
      } else if (val == "产业人才") {
        this.post_params.industry_node = "";
        this.post_params.industry = this.patentParams.industry;
        this.getIndustryTree(this.industrySelected);
        this.getPostInfo();
        this.getPostRank("");
        this.getPostScatter("");
        this.getPostMonthRank("");
        this.getPostCount("");
      }
    },
    // 找下标，删数组内容
    deleteArrayObject(arr, val) {
      let num = null;
      arr.forEach((a, index) => {
        if (a.code == val) {
          num = index;
        }
      });
      arr.splice(num, 1);
    },
    // 获取产业图谱选项
    getIndustryGraphOption() {
      this.optionList = [];
      let industry_graph_permission = cookie.getCookie("industry_graph");
      api.getIndustryGraphOption().then((res) => {
        console.log("option_res", res);
        // this.optionList = res.data;
        let res_data = JSON.parse(JSON.stringify(res.data));
        res.data.forEach((r, idx) => {
          // if (industry_graph_permission.indexOf(r.code) != -1) {
          //   this.optionList.push(r);
          // }
          r.option_list.forEach((o, index) => {
            if (industry_graph_permission.indexOf(o.code) == -1) {
              //   this.optionList.push(o);
              // } else {
              // console.log("else", o);
              // res_data[idx].option_list.splice(index, 1)
              this.deleteArrayObject(res_data[idx].option_list, o.code);
              // console.log("splice", res_data[idx]);
            }
          });
        });
        res_data.forEach((r) => {
          if (r.option_list.length > 0) {
            this.optionList.push(r);
          }
        });
        // this.optionList = res_data
        console.log("optionList", this.optionList);
        console.log("res_data", res_data);
      });
    },
    // 打开网页
    goto(web) {
      window.open(web);
    },
    // 获取公司详情信息
    async getCompanyInfo(row) {
      console.log("val", row);
      let params = {
        full_name: row.full_name,
      };
      await api.getCompanyInfo(params).then((res) => {
        console.log("公司信息", res);
        let info = res.data[0];
        this.companyInfo.entity_name = info.full_name;
        this.companyInfo.telephone = info.telephone;
        this.companyInfo.website = info.website;
        this.companyInfo.email = info.email;
        this.companyInfo.office_addr = info.address;
        this.companyInfo.description = "";
        this.companyInfo.label = "";
        this.companyInfo.legal_representative = info.representative;
        this.companyInfo.company_type = info.enterprises_type;
        this.companyInfo.founded_date = info.establishment;
        this.companyInfo.regis_capital = info.registered_capital;
        this.companyInfo.regis_code = info.taxpayer_num;
        this.companyInfo.headquater = info.region;
        this.companyInfo.employees = info.insured_num;
        this.companyInfo.name_en = info.english_name;
        this.companyInfo.listed = info.is_on_market;
        this.companyInfo.domain = info.business_scope;
        this.companyInfo.industry = info.industry;
        this.companyInfo.company_profile = info.company_profile;
        this.compNameForLogo = info.short_name.substring(0, 4);
        this.chartLinkShortName = info.short_name;
        this.showInfo = true;
      });
      let company_id = await api.getCompanyId(params).then((res) => {
        console.log("获取公司company_id", res.data);
        return res.data;
      });
      this.patentListData = [];
      this.patentParams.company_id = company_id;
      this.getProductTag(company_id);
      this.getLink(company_id);
      this.getFinancialAndProductInfo(company_id);
      this.getHolder(company_id);
      this.getPatentCountAndKeyword(company_id);
      this.getPatentList();
    },
    // 获取下游公司列表
    async getDownstreamCompany(val) {
      let params = {
        category_id: val,
      };
      this.downstreamLoading = true;
      let latentCompAreaList = [];
      let globalCompAreaList = [];
      this.downstreamCompanyList = [];
      this.downstreamCompanyListChina = [];
      this.downstreamCompanyListGlobal = [];
      await api
        .getIndustryCompany(params)
        .then((res) => {
          console.log("下游", res);
          this.downstreamCompanyList = [];
          this.downstreamCompanyListChina = [];
          this.downstreamCompanyListGlobal = [];
          // this.downstreamCompanyList = res.data
          res.data.forEach((r) => {
            if (r.country == "中国") {
              this.downstreamCompanyListChina.push(r);
            } else {
              this.downstreamCompanyListGlobal.push(r);
            }
          });
          if (this.mapTabMarket == 1) {
            this.downstreamCompanyList = this.downstreamCompanyListChina;
          } else {
            this.downstreamCompanyList = this.downstreamCompanyListGlobal;
          }
          let arrProvince = new Set();
          let arrCountry = new Set();
          res.data.forEach((i) => {
            if (i.loc_province && i.country == "中国") {
              arrProvince.add(
                i.loc_province.replace("省", "").replace("市", "")
              );
            }
            if (i.country && i.country != "中国") {
              arrCountry.add(i.country);
            }
          });
          arrProvince.forEach((i) => {
            latentCompAreaList.push({
              loc_province: i,
              value: 0,
            });
          });
          arrCountry.forEach((i) => {
            globalCompAreaList.push({
              name: i,
              value: 0,
            });
          });
          res.data.forEach((item) => {
            for (let i = 0; i < latentCompAreaList.length; i++) {
              if (
                latentCompAreaList[i].loc_province &&
                latentCompAreaList[i].loc_province ==
                  item.loc_province.replace("省", "").replace("市", "")
              ) {
                latentCompAreaList[i].value += 1;
              }
            }
            for (let i = 0; i < globalCompAreaList.length; i++) {
              if (globalCompAreaList[i].name == item.country) {
                globalCompAreaList[i].value += 1;
              }
            }
          });
          // this.downstreamLoading = false;
        })
        .catch((err) => {
          this.downstreamLoading = false;
          console.error(err);
        });
      // document.getElementsByClassName(
      //   "el-table__body-wrapper"
      // )[0].scrollTop = 0;
      this.mapLatentMarket = latentCompAreaList;
      this.worldListMarket = globalCompAreaList;
      this.downstreamLoading = false;
    },
    // 获取产业对应公司列表
    async getIndustryCompany(val) {
      let params = {
        category_id: val,
      };
      this.tableLoading = true;
      let latentCompAreaList = [];
      let globalCompAreaList = [];
      this.tableData = [];
      this.tableDataChina = [];
      this.tableDataGloba = [];
      console.log("tab", this.tab);
      await api
        .getIndustryCompany(params)
        .then((res) => {
          this.tableData = [];
          this.tableDataChina = [];
          this.tableDataGloba = [];
          console.log("产业对应公司列表", res);
          // this.tableData = res.data;
          res.data.forEach((r) => {
            if (r.country == "中国") {
              // this.tableData.push(r)
              this.tableDataChina.push(r);
            } else {
              // this.tableData.push(r)
              this.tableDataGlobal.push(r);
            }
          });
          if (this.tab == 1) {
            this.tableData = this.tableDataChina;
          } else {
            this.tableData = this.tableDataGlobal;
          }
          let arrProvince = new Set();
          let arrCountry = new Set();
          res.data.forEach((i) => {
            if (i.loc_province && i.country == "中国") {
              arrProvince.add(
                i.loc_province.replace("省", "").replace("市", "")
              );
            }
            if (i.country && i.country != "中国") {
              arrCountry.add(i.country);
            }
          });
          arrProvince.forEach((i) => {
            latentCompAreaList.push({
              loc_province: i,
              value: 0,
            });
          });
          arrCountry.forEach((i) => {
            globalCompAreaList.push({
              name: i,
              value: 0,
            });
          });
          res.data.forEach((item) => {
            for (let i = 0; i < latentCompAreaList.length; i++) {
              if (
                latentCompAreaList[i].loc_province ==
                item.loc_province.replace("省", "").replace("市", "")
              ) {
                latentCompAreaList[i].value += 1;
              }
            }
            for (let i = 0; i < globalCompAreaList.length; i++) {
              if (globalCompAreaList[i].name == item.country) {
                globalCompAreaList[i].value += 1;
              }
            }
          });
        })
        .catch((err) => {
          this.tableLoading = false;
        });
      console.log("latentCompAreaList", latentCompAreaList);
      // 重置滚动条
      document.getElementsByClassName(
        "el-table__body-wrapper"
      )[0].scrollTop = 0;
      this.mapLatent = latentCompAreaList;
      this.worldList = globalCompAreaList;
      this.tableLoading = false;
    },
    // 获取产业树
    getIndustryTree(val) {
      let params = {
        root: val,
      };
      this.treeLoading = true;
      let startTime = new Date().valueOf();
      api
        .getIndustryTree(params)
        .then(async (res) => {
          console.log("产业树", res);
          let treeData = [];
          // treeData = [
          //   {
          //     label: val,
          //     id: 0,
          //     children: [

          //     ]
          //   }
          // ]
          res.data.nodes.forEach((i) => {
            if (i.name == val) {
              treeData.push({
                label: val,
                id: i.id,
                children: [],
              });
            }
          });
          console.log("treeData", treeData);
          // res.data.nodes.forEach(async (n) => {
          //   n.category_id = n.id;
          //   n.id = undefined;
          //   n.level_independence = "high";
          //   // 计算第一个节点和1的差值，为了使节点的level整体偏移，因为该关系图默认显示level1和level2的节点
          //   let lv = await res.data.nodes[0].level -1
          //   // console.log("lv", lv)
          //   n.level -=lv
          //   // console.log("level", n.level)
          // });
          // this.nodes = await res.data.nodes;
          // this.links = await res.data.links;
          // // console.log("nodes", this.nodes)
          // // console.log("links", this.links)
          // this.treeLoading = false;
          // let endTime = new Date().valueOf();
          // console.log(
          //   `%c产业树：%c${(endTime - startTime) / 1000}`,
          //   "color: blue;font-weight:bold",
          //   "color: orange;font-weight:bold"
          // );
        })
        .catch((err) => {
          this.treeLoading = false;
        });
    },
    // 切换全球/中国
    changeGlobalOrChina(val, flag) {
      // flag:1代表产业市场地图,2代表产业链条地图
      if (flag == 1 || flag == 2) {
        this.mapTabMarket = val;
        if (val == 1) {
          this.downstreamCompanyList = this.downstreamCompanyListChina;
        } else {
          this.downstreamCompanyList = this.downstreamCompanyListGlobal;
        }
      }
      if (flag == 2) {
        this.tab = val;
        //   if (val == 1) {
        //     this.tableData = this.tableDataChina;
        //   } else {
        //     this.tableData = this.tableDataGlobal;
        //   }
      }
      if (flag == 3) {
        this.mapTabTech = val;
        // if (val == 1) {
        //   this.tableData = this.tableDataChina;
        // } else {
        //   this.tableData = this.tableDataGlobal;
        // }
      }
      // this.getCompanyByProduct();
    },
    // 获取专利技术地图分布
    getIndustryPatentScatter() {
      let params = {
        industry: this.patentParams.industry,
        domain_id: this.patentParams.domain_id,
      };
      this.mapLatentTech = [];
      this.patentScatterLoading = true;
      let startTime = new Date().valueOf();
      api
        .getIndustryPatentScatter(params)
        .then((res) => {
          console.log("专利分布", res);
          this.mapLatentTech = res.data;
          this.patentScatterLoading = false;
          let endTime = new Date().valueOf();
          console.log(
            `%c专利分布：%c${(endTime - startTime) / 1000}`,
            "color: blue;font-weight:bold",
            "color: orange;font-weight:bold"
          );
        })
        .catch((err) => {
          this.patentScatterLoading = false;
        });
    },
    // 获取产业结构各项数据
    getIndustryStructureData(i) {},
    // 点击产业
    handleClickIndustry(i) {
      console.log("点击产业", i);
      console.log(i.name);
      this.isFirst = false;
      // this.getCategoryLink(text);
      this.industrySelected = i.name;
      this.patentParams.industry = i.industry_en;
      console.log("industry_en", i.industry_en);
      this.domain_id = i.domain_id;
      this.patentParams.domain_id = i.domain_id;
      this.tableData = [];
      this.tableDataChina = [];
      this.tableDataGlobal = [];
      // this.getIndustryTree(i.name);
      this.getIndustryPosition();
      this.getIndustryIntroduction();
      this.getCompanyScore();
      console.log("产业", i);
      // this.getDownstreamCompany(i.downstream_id);
      if (i.name == "新能源汽车" || i.name == "热泵空调") {
        this.link_industry = ev;
      } else if (i.name == "疫苗") {
        this.link_industry = yiliao;
      } else if (i.name == "呼吸机") {
        this.link_industry = yiliaoqixie;
      }
      // this.getIndustryCompany(i.domain_id);
      // this.getCompanyByCategory(i.text);
    },
    // 监听重置
    listenReset() {
      EventBus.$on("reset", (data) => {
        // this.newsType = ""
        // this.newsParams.company = ""
        this.company_id = "";
        this.tabsValue = "1";
        console.log("监听重置");
        this.isFirst = true;
        this.tabRadio = "产业市场";
        this.mapTabMarket = 1;
        this.tab = 1;
        this.patentParams.company_guogao_id = "";
        this.patent_list = [];
      });
    },
    // 监听图谱节点点击
    listenIndustryGraph() {
      EventBus.$on("industry_graph", (data) => {
        console.log("industry_graph", data);
        // this.getCompanyByCategory(data.industry_node);
        if (this.tabRadio == "产业链条") {
          this.nodeDesc = data.desc;
          // this.getIndustryCompany(data.domain_id);
          this.domain_id = data.domain_id;
          this.getCompanyScore();
        }
        if (this.tabRadio == "专利技术") {
          this.patent_list = [];
          this.patentParams.page = 1;
          this.patentParams.company_guogao_id = "";
          this.patentParams.domain_id = data.domain_id;
          this.getIndustryPatentCount();
          this.getIndustryPatent();
          this.getIndustryPatentScatter();
          this.getCompanyPatentCount();
          this.getIndustryPatentKeyword();
        }
        if (this.tabRadio == "产业人才") {
          this.post_params.industry_node = data.domain_id;
          this.getPostScatter(data.domain_id);
          this.getPostRank(data.domain_id);
          this.getPostMonthRank(data.domain_id);
          this.getPostCount(data.domain_id);
          this.getPostInfo();
        }
      });
    },
    // 监听源产业变化
    listenSourceIndustry() {
      EventBus.$on("choose_source_industry", (data) => {
        console.log("监听源产业变化", data);
        this.patentParams.industry = data.industry_en;
        this.handleClickIndustry(data);
        this.tabRadio = "产业市场";
      });
    },
    // 监听产业人才排行
    listenCompanyPostBar() {
      EventBus.$on("company_post_bar", (data) => {
        this.post_list = [];
        this.post_params.page = 1;
        this.post_params.company_id = data;
        this.post_params.post_classification = "";
        this.getPostInfo();
      });
    },
    // 监听排行bar变化
    listenCompanyPatentBar() {
      EventBus.$on("company_patent_bar", (data) => {
        console.log("监听排行bar变化", data);
        this.patent_list = [];
        this.patentParams.page = 1;
        this.patentParams.company_guogao_id = data;
        // this.patentParams.industry = ""
        this.patentParams.domain_id = "";
        this.getIndustryPatent();
        this.getIndustryPatentKeyword();
      });
    },
    // 监听index_industry
    listenIndexIndustry() {
      EventBus.$on("index_industry", (data) => {
        console.log("+++++++++++", data);
        this.handleClickIndustry(data);
      });
    },
  },
};
</script>

<style lang="scss">
.CategoryGraph {
  // margin-top: 52px;
  width: 100%;
  padding-bottom: 10px;
  // margin-left: 0.5%;
  // min-height: 80vh;
  // padding-bottom: 30px;
  // max-height: 90vh;
  // display: flex;
  // justify-content: space-between;
  // background-color: white;
  .productAnalyse-patent {
    .el-icon-close::before {
      color: white;
    }
  }
  .el-form-item {
    margin-bottom: 2px;
  }
  &-left,
  &-right {
    // width: 49.6%;
    width: 100%;
    // max-height: 85vh;
    margin-top: 5px;
    background-color: white;
    border-radius: 6px;
    -webkit-box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
    padding-bottom: 5px;
  }
  &-right {
    margin-left: 5px;
  }
  .el-tabs__header {
    margin-bottom: 0;
    padding-left: 15px;
  }
  .contentFooter {
    // margin-bottom: 20px;
    height: 50px !important;
    margin-bottom: 2vh;
  }
  .showMore {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
    color: #333333;
    font-size: 14px;
    p {
      cursor: pointer;
      background-color: rgba(244, 244, 244, 1);
      padding: 5px 40px 5px 40px;
      &:hover {
        background-color: rgb(228, 226, 226);
      }
    }
  }
  .newsEchartsBox {
    background-color: white;
    display: flex;
    min-height: 250px;
    width: 100%;
    // margin-top: 4px;
    border-radius: 6px;
  }
  tr:first-of-type {
    background-color: white;
  }
  #companyTable {
    margin-top: 20px;
  }
  .introduce {
    // position: absolute;
    // bottom: 9vh;
    width: 100%;
    // min-height: 0vh;
    // border: 1px solid #e4eef6;
    // z-index: 1000;
    // transition-duration: 1s;
    // background-color: rgb(0, 0, 51);
    // box-shadow: inset 0px 0px 5px 3px rgb(5, 5, 129);
    .close {
      cursor: pointer;
      width: 23px;
      height: 23px;
      position: absolute;
      right: 10px;
      top: 10px;
    }
    table {
      width: 100%;
      color: #333333;
      margin-left: 0;
      // background-color: rgba($color: #000000, $alpha: 0.4);
      td {
        // border-bottom: solid rgba($color: white, $alpha: 0.3) 1px;
        padding: 10px;
        text-align: center;
      }
      td:nth-child(odd) {
        width: 13%;
        background-color: #f2f9fc;
      }
      td:nth-child(even) {
        width: 20%;
      }
    }
    .description {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    .des {
      overflow: hidden;
      flex: 1;
      padding: 10px;
      color: black;
      p {
        font-size: 14px;
        margin: 5px 0;
        span {
          display: inline-block;
          width: 48%;
          margin: 0 5px;
        }
      }
      h1 {
        margin: 5px 0;
        font-size: 18px;
        span {
          font-size: 14px;
          margin: 0 5px;
          //   background: #E7F4FF;
          color: cornflowerblue;
        }
      }
    }
    .detail {
      width: 100%;
      // max-width: 1200px;
      &-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        // background-color: rgba(91, 91, 94, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          color: #d3feff;
          font-size: 30px;
          font-weight: bold;
        }
      }
      .detailTop {
        display: flex;
        // background-color: rgba($color: #000000, $alpha: 0.4);
        border-bottom: solid silver 1px;
        // img {
        //   margin: 20px 10px 20px 25px;
        //   object-fit: contain;
        //   height: 80px;
        //   width: 80px;
        //   background: white;
        // }
      }
      .compLogo {
        margin: 20px 10px 20px 25px;
        height: 80px;
        width: 80px;
        // background-color: white;
        // border: 1px solid gray;
        background-color: #01a4d9;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          width: 80px;
          overflow: hidden;
          word-break: break-all;
          word-wrap: wrap;
          margin-left: 7px;
          text-align: center;
          color: white;
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 8px;
        }
      }
    }
  }
  .el-dialog__body {
    padding-top: 10px;
  }
  .radioBox {
    width: 100%;
    // position: absolute;
    // z-index: 1000;
    background-color: white;
    margin-top: 4px;
    padding-left: 15px;
    padding-top: 10px;
  }
  .nodeDesc {
    padding: 0 10px 0 10px;
    font-size: 15px;
  }
  table {
    margin-left: 0;
  }
  .market {
    padding: 0 15px 0 15px;
    margin-top: 20px;
    // display: flex;
    // justify-content: space-between;
    &-top {
      width: 98%;
      .industryDesc {
        margin-top: 10px;
        text-indent: 35px;
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 20px;
      }
    }
    &-bottom {
      width: 98%;
      display: flex;
      justify-content: space-between;
    }
  }
  .tech {
    width: 100%;
    &-top {
      display: flex;
    }
  }
  .doubleArrow {
    width: 90%;
    margin-left: 5%;
  }
  .mores {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    &-text {
      cursor: pointer;
      background-color: #f4f4f4;
      padding: 5px 40px 5px 40px;
      &:hover {
        background-color: #d8d7d7;
      }
    }
  }
  .el-table--scrollable-x .el-table__body-wrapper {
    overflow: auto !important;
  }
  .el-table__body-wrapper::-webkit-scrollbar {
    width: 8px; // 横向滚动条
    height: 10px; // 纵向滚动条 必写
  }
  .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: rgb(205, 205, 221);
    border-radius: 5px;
  }
  .postDetail {
    &-post {
      font-size: 20px;
      font-weight: bold;
      color: black;
      display: flex;
      justify-content: space-between;
      padding-right: 15px;
      span {
        font-size: 18px;
        color: #5c7bd9;
      }
    }
    &-company {
      font-size: 16px;
      margin-top: 5px;
    }
    &-info {
      margin-top: 25px;
    }
    &-line {
      margin: 0 5px 0 5px;
      color: silver;
    }
    &-position_info {
      margin-top: 20px;
    }
    &-url {
      margin-top: 10px;
    }
  }
  .tagBox {
    &-tag {
      margin-right: 7px;
      margin-bottom: 5px;
      cursor: pointer;
    }
  }
}
</style>
